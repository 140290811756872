import React from 'react'
import _isEqual from 'lodash/isEqual'
import { MAP } from '../App.config'
import { MapboxStyleSwitcherControl } from 'mapbox-gl-style-switcher'

// Import Styles
import 'mapbox-gl-style-switcher/styles.css'

class MapGL extends React.PureComponent {
    // Initial State
	state = {
		container: 'post-office-map',
		center: [90.3938010872331, 23.821600277500405],
		zoom: 11,
		map: null,
	}

    // Create Map Instance
    componentDidMount() {
		this._createMap()
	}

    // Update Map When Changes prevProps/prevState
    componentDidUpdate(prevProps, prevState){
        const { geoJsonData, centerPoint } = this.props
		const { map, center } = this.state

        // If Map Changes In State
		if (prevState.map !== map) {

			// Render GeoJson Data
			this._renderGeoJsonData(geoJsonData, centerPoint)
		}

        // If GeoJsonData Changes in Props
		if(!_isEqual(prevProps.geoJsonData, geoJsonData)) {

			// Render GeoJson Data
			this._renderGeoJsonData(geoJsonData)
		}

        // If Center Point Changes
		if(centerPoint?.length > 0){
			if(!_isEqual(center, centerPoint)) {
                
				// Render Center Point
				map.flyTo({
					center: centerPoint					
				})
			}		
		}
    }

    componentWillUnmount() {
		// Destroy Map Instance
		this._destroyMap()
	}

    // Create Map
    _createMap = () => {
        const { container, center, zoom } = this.state

        const map = new window.bkoigl.Map({
			container,
			center,
			zoom,
			accessToken: MAP.ACCESS_TOKEN,
			attributionControl: false,
			style: 'https://map.barikoi.com/styles/osm-liberty/style.json',
		})

        // Add Controls
		map.addControl(new window.bkoigl.NavigationControl(), 'bottom-right')
		map.addControl(new window.bkoigl.FullscreenControl())
		map.addControl(new MapboxStyleSwitcherControl(MAP.STYLES, 'One Map'), 'top-right')

		// Disable Double Click Zoom
		map.doubleClickZoom.disable();

		this.setState({ map });
    }

    // Destroy Map
    _destroyMap = () => {
        const { map } = this.state;

		// Remove Map Instance
		if (map) {
			map.remove();
			this.setState({ map: null });
		}
    }

	// Render GeoJsonData
	_renderGeoJsonData = (geoJsonData, centerPoint) => {
		const { map } = this.state

		// Remove Existing GeojsonData
		this._removeRenderedGeoJsonData()
	
		if(!geoJsonData?.length) {
		  return
		}

		if(map.isStyleLoaded()) {
			geoJsonData.forEach((g) => {
	
				// Add GeoJson Data Source To Map
				map.addSource(g.id, {
					type: 'geojson',
					data: g?.featureCollection ?? null,
					generateId: true
				})
	
				// Add GeoJson Data Layer
				if(g.type === "text") {
					// Add Text Layer
					map.addLayer({
						'id': g.id + "-text",
						'type': 'symbol',
						'source': g.id,
						'layout': {
							'visibility': 'visible',
							'text-field': ['get', 'label'],
							'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
							'text-radial-offset': 0.5,
							'text-justify': 'auto',
							'icon-image': ['get', 'icon']
						},
						'filter': [ '==', '$type', 'Point' ]
					});
		
				} else if(g.type === "polygon") {
					// Add Polygon Layer
					map.addLayer({
						'id': g.id + "-polygon",
						'type': 'fill',
						'source': g.id,
						'layout': {
						'visibility': 'visible'
						},
						'paint': {
							'fill-color': g?.properties?.color ?? '#FF0000',
							'fill-opacity': 0.25,
						},
						'filter': [ '==', '$type', 'Polygon' ]
					});
			
					// Add boundary around the polygon
					map.addLayer({
						'id': g.id + "-boundary",
						'type': 'line',
						'source': g.id,
						'layout': {},
						'paint': {
							'line-color': g?.properties?.boundary ?? '#000',
							'line-width': 2
						}
					});	
				}
			})
			this.setState({ renderedGeoJsonData: geoJsonData })
		}
	}

	// Remove Rendered GeoJson Data
	_removeRenderedGeoJsonData = () => {
	const { map, renderedGeoJsonData } = this.state

	if(!renderedGeoJsonData?.length) {
		return
	}			

	// Remove GeoJson Layers
	renderedGeoJsonData.forEach((g) => {

		// Remove Previous Layer By Id
		if(map.getLayer(g.id + "-text")) {   
			map.removeLayer(g.id + "-text")
		}

		if(map.getLayer(g.id + "-polygon")) {
			map.removeLayer(g.id + "-polygon")
		}

		if(map.getLayer(g.id + "-boundary")) {   
			map.removeLayer(g.id + "-boundary")
		}

		// Remove Previous Source By Id
		if(map.getSource(g.id)) {
			map.removeSource(g.id)
		}
	})


	this.setState({ renderedGeoJsonData: [] })
	}

    render() {
		const { container } = this.state
		return <div id={container} style={containerStyles} />
	}
}

// Styles
const containerStyles = {
	boxSizing: 'border-box',
	margin: 0,
	padding: 0,
	width: '100%',
	height: '100%',
	overflow: 'hidden',
	border: '1px solid #dcdcdc',
	borderRadius: '4px',
}

export default MapGL