export const BASE_URL = window?._env_?.REACT_APP_BASE_URL
export const MAP_API_ACCESS_TOKEN = window?._env_?.REACT_APP_MAP_API_ACCESS_TOKEN

// API Configs
export const API = {
	GET_DIVISIONS: `${BASE_URL}/api/get-divisions`,
	GET_DISTRICT: `${BASE_URL}/api/get-district`,
	GET_UPAZILA: `${BASE_URL}/api/get-subdistrict`,
	GET_UNION: `${BASE_URL}/api/get-union`,
	GET_VILLAGE: `${BASE_URL}/api/get-village`,
	GET_POSTOFFICE_INFO: `${BASE_URL}/api/search`,
}

// Map Configs
export const MAP = {
	ACCESS_TOKEN: MAP_API_ACCESS_TOKEN,
	STYLES: [
		{
			title: 'One Map',
			uri: `https://map.barikoi.com/styles/OneMap/style.json?key=${MAP_API_ACCESS_TOKEN}`,
		},
		{
			title: 'OSM Liberty',
			uri: `https://map.barikoi.com/styles/osm-liberty/style.json?key=${MAP_API_ACCESS_TOKEN}`,
		},
		{
			title: 'Dark',
			uri: `https://map.barikoi.com/styles/barikoi-dark/style.json?key=${MAP_API_ACCESS_TOKEN}`,
		},
	],
};

