import React, { useState, useEffect } from 'react'

// Import Components
import { Button, Form, Select, Space } from 'antd'

// Import Actions
import { getDistrictByDivisionId, getDivisions, getPostOfficeInfo, getUnionByUpazilaId, getUpazilaByDistrictId, getVillageByUnionId } from '../api'

// Import Utility Function
import { randomColor } from '../utils/randomColor'


// Import Constants
const { Option } = Select

const FormContainer = ({ setGeoJsonData, setCenterPoint }) => {

    // States
    const [form] = Form.useForm()
    const [divisionOptions, setDivisionOptions] = useState([])
    const [districtOptions, setDistrictOptions] = useState([])
    const [upazilaOptions, setUpazilaOptions] = useState([])
    const [unionOptions, setUnionOptions] = useState([])
    const [villageOptions, setVillageOptions] = useState([])
    const [postOffice, setPostOffice] = useState('')
    const [postCode, setPostCode] = useState('')
    const [isFetched, setIsFetched] = useState(false)
    const [postOfficeInfo, setPostOfficeInfo] = useState({})

    // On Submit
    const _onSubmit = ({ subdistrict_id, union_id, village_id }) => {

        // Clear Prev States
        form.resetFields()
        setDistrictOptions([])
        setUpazilaOptions([])
        setUnionOptions([])
        setVillageOptions([])
        setGeoJsonData([])

        // Get Data
        if (!subdistrict_id && !union_id && village_id) {
            getPostOfficeInfo({ subdistrict_id: null, union_id: null, village_id })
                .then(res => {
                    setPostOfficeInfo(res)
                    setIsFetched(true)
                })
        } else if (subdistrict_id && union_id && !village_id) {
            getPostOfficeInfo({ subdistrict_id: null, union_id, village_id: null })
                .then(res => {
                    setPostOfficeInfo(res)
                    setIsFetched(true)
                })
        } else if (subdistrict_id && !union_id && !village_id) {
            getPostOfficeInfo({ subdistrict_id, union_id: null, village_id: null })
                .then(res => {
                    setPostOfficeInfo(res)
                    setIsFetched(true)
                })
        } else {
            getPostOfficeInfo({ subdistrict_id: null, union_id: null, village_id: village_id })
                .then(res => {
                    setPostOfficeInfo(res)
                    setIsFetched(true)
                })
        }

    }

    // Render Map
    if (isFetched) {
        // Set Post Office
        setPostOffice(postOfficeInfo?.post_office)

        // Set Post Code
        setPostCode(postOfficeInfo?.post_code)

        // Map 
        postOfficeInfo?.polygon?.forEach((polygon) => {

            // Calculate Center Point
            if (polygon?.type === "MultiPolygon") {
                const centerPoint = polygon?.coordinates?.[0]?.[0]?.[0]
                setCenterPoint(centerPoint)
            } else if (polygon?.type === "Polygon") {
                const centerPoint = polygon?.coordinates?.[0]?.[0]
                setCenterPoint(centerPoint)
            }

            // Random Color
            let color = randomColor()

            // Set GeoJsonData
            setGeoJsonData((prevState) => [...prevState, {
                id: `postcode-polygon-${Math.floor(Math.random() * 100000)}`,
                type: 'polygon',
                featureCollection: {
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            geometry: {
                                coordinates: polygon?.coordinates,
                                type: polygon?.type
                            }
                        }
                    ]
                },
                properties: {
                    color: color,
                    boundary: color,
                }
            }])
        })

        setIsFetched(false)
    }

    // On Clear
    const _onClear = () => {
        form.resetFields()
        setDistrictOptions([])
        setUpazilaOptions([])
        setUnionOptions([])
        setVillageOptions([])
        setPostCode('')
        setPostOffice('')
        setGeoJsonData([])
    }

    // On Change Division
    const _onChangeDivision = (id) => {

        // Clear Prev States
        setDistrictOptions([])
        setUpazilaOptions([])
        setUnionOptions([])
        setVillageOptions([])

        // Get Data
        getDistrictByDivisionId(id)
            .then(res => setDistrictOptions(res ?? []))
    }

    // On Change District
    const _onChangeDistrict = (id) => {

        // Clear Prev States
        setUpazilaOptions([])
        setUnionOptions([])
        setVillageOptions([])

        // Get Data
        getUpazilaByDistrictId(id)
            .then(res => setUpazilaOptions(res ?? []))
    }

    // On Change Upazila
    const _onChangeUpazila = (id) => {

        // Clear Prev States
        setUnionOptions([])
        setVillageOptions([])

        // Get Data
        getUnionByUpazilaId(id)
            .then(res => setUnionOptions(res ?? []))
    }

    // On Change Union
    const _onChangeUnion = (id) => {

        // Clear Prev States
        setVillageOptions([])

        // Get Data
        getVillageByUnionId(id)
            .then(res => setVillageOptions(res ?? []))
    }

    // Get Data
    useEffect(() => {
        getDivisions()
            .then(res => setDivisionOptions(res ?? []))
    }, [])

    return (
        <div>
            <Form
                name='post-office'
                onFinish={_onSubmit}
                layout='vertical'
                form={form}
            >
                {/* Division */}
                <Form.Item
                    name='division_id'
                    label='বিভাগ'
                    rules={[
                        {
                            required: true,
                            message: 'বিভাগ নির্বাচন করুন'
                        }
                    ]}
                >
                    <Select
                        allowClear
                        showSearch
                        optionFilterProp='children'
                        placeholder='বিভাগ নির্বাচন করুন'
                        onChange={_onChangeDivision}
                    >
                        {divisionOptions?.map((division) =>
                            <Option key={division.id} value={division.id}>{division.name_bn}</Option>
                        )}
                    </Select>
                </Form.Item>

                {/* District */}
                <Form.Item
                    name='district_id'
                    label='জেলা'
                    rules={[
                        {
                            required: true,
                            message: 'জেলা নির্বাচন করুন'
                        }
                    ]}
                >
                    <Select
                        allowClear
                        showSearch
                        optionFilterProp='children'
                        placeholder='জেলা নির্বাচন করুন'
                        onChange={_onChangeDistrict}
                    >
                        {districtOptions?.map((district) =>
                            <Option key={district.id} value={district.id}>{district.name_bn}</Option>
                        )}
                    </Select>
                </Form.Item>

                {/* Upazila */}
                <Form.Item
                    name='subdistrict_id'
                    label='উপজেলা'
                    rules={[
                        {
                            required: true,
                            message: 'উপজেলা নির্বাচন করুন'
                        }
                    ]}
                >
                    <Select
                        allowClear
                        optionFilterProp='children'
                        placeholder='উপজেলা নির্বাচন করুন'
                        onChange={_onChangeUpazila}
                    >
                        {upazilaOptions?.map((upazila) =>
                            <Option key={upazila.id} value={upazila.id}>{upazila.name_bn}</Option>
                        )}
                    </Select>
                </Form.Item>

                {/* Union */}
                <Form.Item
                    name='union_id'
                    label='ইউনিয়ন'
                >
                    <Select
                        allowClear
                        optionFilterProp='children'
                        placeholder='ইউনিয়ন নির্বাচন করুন'
                        onChange={_onChangeUnion}
                    >
                        {unionOptions?.map((union) =>
                            <Option key={union.id} value={union.id}>{union.name_bn}</Option>
                        )}
                    </Select>
                </Form.Item>

                {/* Village */}
                <Form.Item
                    name='village_id'
                    label='গ্রাম'
                >
                    <Select
                        allowClear
                        optionFilterProp='children'
                        placeholder='গ্রাম নির্বাচন করুন'
                    >
                        {villageOptions?.map((village) =>
                            <Option key={village.id} value={village.id}>{village.name_bn}</Option>
                        )}
                    </Select>
                </Form.Item>

                {/* Buttons */}
                <Form.Item>
                    <Space size={8}>
                        <Button type='primary' htmlType='submit' style={buttonStyles}>অনুসন্ধান করুন</Button>
                        <Button type='primary' danger onClick={_onClear} style={buttonStyles}>মুছে ফেলুন</Button>
                    </Space>
                </Form.Item>
            </Form>

            {/* Show Results */}
            <table style={{ width: '100%' }}>
                <thead>
                    <tr>
                        <th style={{ border: '1px solid #000' }}>Post Office</th>
                        <th style={{ border: '1px solid #000' }}>Post Code</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ border: '1px solid #000', height: '20px', textAlign: 'center' }}>{postOffice}</td>
                        <td style={{ border: '1px solid #000', height: '20px', textAlign: 'center' }}>{postCode}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
// Styles
const buttonStyles = {
    fontWeight: 'bold',
    letterSpacing: '1.5px'
}
export default FormContainer