import React from 'react';

// Import Components
import { Row, Col } from 'antd'
import MapGL from './MapGL';

const MapContainer = ({ geoJsonData, centerPoint }) => {
    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <div style={ mapContainerStyles }>
                    <MapGL geoJsonData={ geoJsonData } centerPoint={ centerPoint }/>
                </div>
            </Col>
        </Row>
    );
};

// Styles
const mapContainerStyles = {
    width: '100%', 
    height: '100%',
    minHeight: '600px'
}
export default MapContainer;