import axios from "axios"
import { API } from "../App.config"
import { message } from "antd"

// Get Divisions
export async function getDivisions() {
    return await axios.get(API.GET_DIVISIONS)
        .then(res => {
            if (res.status === 200) {
                return res?.data?.data ?? []
            }
        })
        .catch(err => {
            console.error(err)
        })
}

// Get District by Division Id
export async function getDistrictByDivisionId(id) {
    return await axios.get(`${API.GET_DISTRICT}?division_id=${id}`)
        .then(res => {
            if (res.status === 200) {
                return res?.data?.data
            }
        })
        .catch(err => {
            console.error(err)
        })
}

// Get Upazila by District Id
export async function getUpazilaByDistrictId(id) {
    return await axios.get(`${API.GET_UPAZILA}?district_id=${id}`)
        .then(res => {
            if (res.status === 200) {
                return res?.data?.data
            }
        })
        .catch(err => {
            console.error(err)
        })
}

// Get Union by Upazila Id
export async function getUnionByUpazilaId(id) {
    return await axios.get(`${API.GET_UNION}?subdistrict_id=${id}`)
        .then(res => {
            if (res.status === 200) {
                return res?.data?.data
            }
        })
        .catch(err => {
            console.error(err)
        })
}

// Get Village by Union Id
export async function getVillageByUnionId(id) {
    return await axios.get(`${API.GET_VILLAGE}?union_id=${id}`)
        .then(res => {
            if (res.status === 200) {
                return res?.data?.data
            }
        })
        .catch(err => {
            console.error(err)
        })
}

// Get Post Office Info by Village Id
export async function getPostOfficeInfo(obj) {
    return await axios.post(`${API.GET_POSTOFFICE_INFO}`, obj)
        .then(res => {
            if (res.status === 200) {
                if (res?.data?.data?.post_code === 'Not Found') {

                    message.warning({ key: 'not-found', content: 'No Data Found', style: { marginTop: '2.5vh' } })
                }

                return res?.data?.data
            }
        })
        .catch(err => {
            message.warning({ key: 'error', content: 'Something Went Wrong' })
            console.error(err)
        })
}
