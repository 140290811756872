import { useState } from 'react'

// Import Components
import { Col, Row, message } from 'antd'

// Import Styles
import './App.css'

// Import Components
import FormContainer from './components/FormContainer'
import MapContainer from './components/MapContainer'

function App() {
  // States
  const [geoJsonData, setGeoJsonData] = useState([])
  const [centerPoint, setCenterPoint] = useState([])
  const [_, contextHolder] = message.useMessage();

  return (
    <div style={containerStyles}>
      {contextHolder}
      <Row justify='center' align='middle' gutter={[32, 32]}>
        <Col span={24}><h1 style={{ textAlign: 'center', fontSize: '35px', fontWeight: 'bold' }}>Postal Search</h1></Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <FormContainer setGeoJsonData={setGeoJsonData} setCenterPoint={setCenterPoint} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12}>
          <MapContainer geoJsonData={geoJsonData} centerPoint={centerPoint} />
        </Col>
      </Row>
    </div>
  )
}

// Styles
const containerStyles = {
  width: '100%',
  height: '100%',
  margin: '50px 0 100px 0',
  padding: '20px'
}

export default App
